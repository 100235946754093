import axios from 'axios'
const url = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: url + '/'
})


axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('purimax-auth-token')
    config.headers = Object.assign({}, config.headers, {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`      
    })
    
    if ( config.headers['Authorization'] ) return config
    
  }, function (error) {
    return Promise.reject(error)
  }
)


axiosInstance.interceptors.response.use(
  response => {
    return response
  }, error => {
    
    let app = global.app

    if (error.response.status === 400 ) {
      app.$root.toast.show( {message: error.response.data.message, color: 'warning', icon: 'mdi-alert-outline' } )
    } else if (error.response.status === 401) {
      localStorage.clear()
      localStorage.setItem('logoutMessage', error.response.data.error);
      window.location.href='/login'
    } else if (error.response.status === 422) {
      app.$root.toast.show( {message: Object.values(error.response.data).join( "<br />") , color: 'warning', icon: 'mdi-alert-outline' } )
    } else {
      return Promise.reject(error)
    }
  }
)

export const api = {
  url,
  get (endpoint, params) {
    return axiosInstance.get(endpoint, { params })
  },
  post (endpoint, body) {
    return axiosInstance.post(endpoint, body)
  },
  put (endpoint, body) {
    return axiosInstance.put(endpoint, body)
  },
  delete (endpoint) {
    return axiosInstance.delete(endpoint)
  },
  login (body) {
    return axios.post(`${url}/login`, body, { header:{'Content-Type': 'application/json', 'Accept': 'application/json' }})
  },
  validateToken () {
    return axiosInstance.get(url + '/auth')
  }
}

//const toUrlEncoded = obj => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')