
export const config = {
  
  PAYMENT_METHODS : [
    { type: 0, order: 0, label: "Cartão de Crédito" },
    { type: 1, order: 5, label: "Cartão de Débito" },
    { type: 2, order: 10, label: "Dinheiro" },
    { type: 3, order: 15, label: "Cheque" },
    { type: 4, order: 20, label: "Pix" },
  ],


  ORDER_STATUS : [
    { type: 0, order: 0, label: "Pendente"},
    { type: 1, order: 5, label: "Entregue"},
    { type: 2, order: 10, label: "Cancelado"},
    { type: 3, order: 1, label: "Saiu para entrega"},
  ],

}


