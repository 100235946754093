import Vue from "vue"
import moment from 'moment'


Vue.filter('money', function (value) {
    value = parseFloat( value )

    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });

    return formatter.format(value);  
});


Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY hh:mm:ss')
    }
});


function mask(v, m){
    let str = '';
    let k = 0;
    for( let i = 0; i < m.length; i++ ){
        str += ( m[ i ] === '#' ) ? (  (v?.[k]) ? v[ k++ ] : '' ) : m[i];
    }
    return str;
}

Vue.filter('phoneFormat', function(v) {
    
    if (v) {
        let maskformat;
        v = v.replace(/\D/g, "")

        if( v.length === 13 )
            maskformat = '+## ## #####-####'; // celular
        else if( v.length === 12 )  
            maskformat = '+## ## ####-####'; // fixo
        else
            maskformat = '## ####-####'; // fixo sem país

      return mask( v, maskformat)
    }
});