import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import WhatsAppIndex from './views/WhatsApp/Index.vue'
import CadastroEncomenda from '@/views/Encomendas/CadastroEncomenda.vue';
import ListarEncomendas from '@/views/Encomendas/ListarEncomendas.vue';



Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes:[
        { 
            path: '*',
            beforeEnter: (to, from, next) => { next({ name: 'login' }) }
        },
        
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login')
        },
        {
            path: '/',
            name: 'dashboard',
            title: 'Dashboard',
            component: () => import('@/views/Dashboard'),
            meta: { login: true }
        },

        {
            path: '/clients',
            name: 'clients',
            title: 'Clients',
            component: () => import('@/views/Clients/Index'),
            meta: { login: true }
        },

        {
            path: '/orders',
            name: 'orders',
            title: 'Orders',
            component: () => import('@/views/Orders/Index'),
            meta: { login: true }
        },

        {
            path: '/orders/add',
            name: 'orders-add',
            title: 'Orders',
            component: () => import('@/views/Orders/Order'),
            meta: { login: true }
        },

        {
            path: '/orders/edit/:id',
            name: 'orders-edit',
            title: 'Orders',
            component: () => import('@/views/Orders/Order'),
            meta: { login: true }
        },

        {
            path: '/protocols',
            name: 'protocols',
            title: 'Protocols',
            component: () => import('@/views/Protocols/Index'),
            meta: { login: true }
        },

        {
            path: '/protocols/add',
            name: 'protocols-add',
            title: 'Protocols',
            component: () => import('@/views/Protocols/Protocol'),
            meta: { login: true }
        },

        {
            path: '/protocols/edit/:id',
            name: 'protocols-edit',
            title: 'Protocols',
            component: () => import('@/views/Protocols/Protocol'),
            meta: { login: true }
        },

        {
            path: '/whatsapp',
            name: 'WhatsApp',
            component: WhatsAppIndex, 
            meta: { login: true }
        },

        {
            path: '/encomendas/cadastro',
            name: 'CadastroEncomenda',
            component: CadastroEncomenda,
            meta: { login: true }
        },

        {
            path: '/encomendas/listagem',
            name: 'ListarEncomendas',
            component: ListarEncomendas,
            meta: { login: true }
        },

        {
            path: '/couriers',
            name: 'couriers',
            title: 'Couriers',
            component: () => import('@/views/Couriers/Index'),
            meta: { login: true }
        },

        {
            path: '/couriers/add',
            name: 'couriers-add',
            title: 'Couriers',
            component: () => import('@/views/Couriers/Courier'),
            meta: { login: true }
        },

        {
            path: '/couriers/edit/:id',
            name: 'couriers-edit',
            title: 'Couriers',
            component: () => import('@/views/Couriers/Courier'),
            meta: { login: true }
        },


        {
            path: '/users',
            name: 'users',
            title: 'Users',
            component: () => import('@/views/Users/Index'),
            meta: { login: true }
        },

        {
            path: '/users/add',
            name: 'users-add',
            title: 'Users',
            component: () => import('@/views/Users/User'),
            meta: { login: true }
        },

        {
            path: '/users/edit/:id',
            name: 'users-edit',
            title: 'Users',
            component: () => import('@/views/Users/User'),
            meta: { login: true }
        },

        {
            path: '/roles',
            name: 'roles',
            title: 'Roles',
            component: () => import('@/views/Users/Roles/Index'),
            meta: { login: true }
        },

        {
            path: '/roles/add',
            name: 'roles-add',
            title: 'Roles',
            component: () => import('@/views/Users/Roles/Role'),
            meta: { login: true }
        },

        {
            path: '/roles/edit/:id',
            name: 'roles-edit',
            title: 'Roles',
            component: () => import('@/views/Users/Roles/Role'),
            meta: { login: true }
        },


        {
            path: '/products',
            name: 'products',
            title: 'Products',
            component: () => import('@/views/Products/Index'),
            meta: { login: true }
        },
        
    ],

    scrollBehavior() { return window.scrollTo({ top: 0, behavior: 'smooth' }) }
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.login)) {
        let token = localStorage.getItem( 'purimax-auth-token' )

        if( !token || token == '' ){
            return next({ name: 'login' })
        } else {
            if(!store.state.user){
                await store.dispatch('loadUser');
            }            
            return next()
        }
    }
    
    return next() // make sure to always call next()!
    
})

export default router