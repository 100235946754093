import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

const opts = {icons: {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken4,
        secondary: colors.orange.darken3,
        accent: colors.cyan.base,
      },
    },
  },
}

export default new Vuetify(opts)
