<template>
    <div class="pa-10">
        <h1>Cadastro de Encomenda</h1>

        <v-form v-model="valid" ref="formEncomenda">
            <v-container fluid>
                <!-- Nome do Cliente -->
                <v-row align="center" justify="center">
                    <v-col cols="12" md="8">
                        <v-text-field
                            v-model="encomenda.cliente_nome"
                            label="Nome do Cliente"
                            outlined
                            required
                            hide-details
                        />
                    </v-col>
                </v-row>

                <!-- Número do WhatsApp -->
                <v-row align="center" justify="center">
                    <v-col cols="12" md="8">
                        <v-text-field
                            v-model="encomenda.whatsapp_numero"
                            label="Número do WhatsApp"
                            outlined
                            required
                            placeholder="Ex: 32 99951-4091"
                            hide-details
                            v-mask="['(##) ####-####', '(##) #####-####']"
                        />
                    </v-col>
                </v-row>

                <!-- Nome do Produto -->
                <v-row align="center" justify="center">
                    <v-col cols="12" md="8">
                        <v-text-field
                            v-model="encomenda.produto_nome"
                            label="Nome do Produto"
                            outlined
                            required
                            hide-details
                        />
                    </v-col>
                </v-row>

                <!-- Data Prevista -->
                <v-row align="center" justify="center">
                    <v-col cols="12" md="8">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="encomenda.data_prevista"
                                    label="Data Prevista"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                />
                            </template>
                            <v-date-picker
                                v-model="encomenda.data_prevista"
                                @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>

                <!-- Botão de Cadastro -->
                <v-row align="center" justify="center" class="mt-5">
                    <v-col cols="12" md="8">
                        <v-btn color="success" x-large @click="cadastrarEncomenda" :disabled="!valid">
                            Cadastrar Encomenda
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import { cadastrarEncomenda } from '@/services/Encomendas'; // Importando a função da API

export default {
    name: 'CadastroEncomenda',
    data() {
        return {
            valid: false,
            menu: false, // para o menu de data
            encomenda: {
                cliente_nome: '',
                whatsapp_numero: '',
                produto_nome: '',
                data_prevista: ''
            }
        };
    },
    methods: {
        async cadastrarEncomenda() {
            if (this.$refs.formEncomenda.validate()) {
                try {
                    await cadastrarEncomenda(this.encomenda); // Chamada à função da API
                    this.$root.toast.show({
                        message: 'Encomenda cadastrada com sucesso!',
                        color: 'success'
                    });
                    // Limpar o formulário após cadastro
                    this.encomenda = {
                        cliente_nome: '',
                        whatsapp_numero: '',
                        produto_nome: '',
                        data_prevista: ''
                    };
                } catch (error) {
                    console.error('Erro ao cadastrar encomenda:', error);
                    this.$root.toast.show({
                        message: 'Erro ao cadastrar encomenda! Verifique os dados.',
                        color: 'error'
                    });
                }
            } else {
                this.$root.toast.show({
                    message: 'Por favor, preencha todos os campos obrigatórios.',
                    color: 'warning'
                });
            }
        }
    }
};
</script>

<style scoped>
.pa-10 {
    padding: 10px;
}
</style>
