<template lang="pug">
  div.pa-10
      PageTitle(:label="'WhatsApp'")

      div(v-acl.cant.hide="'whatsapp.view'") Você não possui a permissão necessária para acessar o recurso

      div(v-acl.can.hide="'whatsapp.view'")
          v-form(v-model="valid")
              
              v-container(fluid)
                  h3.mb-10.mt-1 Enviar Mensagem via WhatsApp

                  v-row(align="center" justify="center")
                      v-col(cols="12" md="8")
                          v-text-field(
                              v-model="whatsappNumber"
                              label="Número do WhatsApp"
                              outlined
                              hide-details
                              required
                              placeholder="Ex: 32999999999"
                          )

                  v-row(align="center" justify="center")
                      v-col(cols="12" md="8")
                          v-textarea(
                              v-model="whatsappMessage"
                              label="Mensagem"
                              outlined
                              hide-details
                              required
                          )

                  v-row(align="center" justify="center" class="mt-5")
                      v-col(cols="12" md="8")
                          v-btn(color="success" x-large @click="sendWhatsAppMessage")
                              Enviar Abrir Conversa no WhatsApp
                              v-icon(right) mdi-whatsapp
</template>

<script>
import { api } from "@/api";

export default {
  name: "WhatsAppIndex",
  components: {
      PageTitle: () => import('@/components/PageTitle'),
      confirm: () => import( '@/components/Confirm' ),
  },
  data() {
      return {
          valid: false,
          whatsappNumber: '',
          whatsappMessage: ''
      };
  },
  methods: {
      async sendWhatsAppMessage() {
          if (!this.whatsappNumber || !this.whatsappMessage) {
              this.$root.toast.show({
                  message: 'Informe o número do WhatsApp e a mensagem',
                  color: 'warning',
                  icon: 'mdi-alert-outline'
              });
              return;
          }

          try {

            let formattedNumber = this.whatsappNumber.replace(/\D/g, ''); 
            if (!formattedNumber.startsWith('55')) {
              formattedNumber = '55' + formattedNumber;
            }

            const payload = {
                number: formattedNumber,
                message: this.whatsappMessage
            };
            
            const response = await api.post('whatsapp/send', payload);

            if (response.data.success) {
                this.$root.toast.show({
                    message: 'Mensagem enviada com sucesso!',
                    color: 'success',
                    icon: 'mdi-check-circle'
                });

                this.whatsappNumber = '';
                this.whatsappMessage = '';
            } else {
                throw new Error(response.data.message || 'Erro ao enviar mensagem!');
            }
          } catch (error) {
              this.$root.toast.show({
                  message: error.message || 'Erro ao enviar mensagem!',
                  color: 'error',
                  icon: 'mdi-alert-circle'
              });
          }
      }
  }
};
</script>
