import store from "../store";

const ACL = {
  install:  function (Vue, store) {

    const ACL = function ( slug ) {
      
      if ( store.state.user?.role?.super ) {
        return true;
      }

      if (store.state.user?.role?.permissions) {
        return store.state.user.role.permissions.find( permission => permission.slug === slug );
      }

      return false;
    }

    Vue.prototype.acl = ACL


    Vue.directive('acl', {
      inserted (el, binding, vNode) {
        const modifiers = binding.modifiers;
        
        const execAction = function() {
          if (modifiers?.hide) {
              vNode.elm.parentElement.removeChild(vNode.elm);
          } else if (modifiers?.disable) {
              el.disabled = true;
              el.title = "Você não possui permissão.";
              el.style.cursor='not-allowed';
          }
        }

        if ( (modifiers?.can && !ACL(binding.value)) || (modifiers?.cant && ACL(binding.value))) {
          execAction();
          return true;
        }

        return false;        
        
      }
    });

  },
  store
}

export default ACL;