<template lang="pug">
  #app
    v-app( :class="['fill-height', {'login' : isLogin }]" )
     
      menu-top(v-if="!isLogin")
      v-main
        v-alert( border="left" elevation="8" type="warning" transition="scroll-y-transition" v-show="!connection") Internet connection fail...
        router-view(:key="$route.fullPath")
      
      toast(ref="toast")
      
    
</template>

<script>

import Menu from '@/partials/Menu'
import Toast from './components/Toast'

export default {
  name: 'App',
  data: function(){
    return {
      connection: true,
      isLogin: true,
    }
  },
  components: {
    'menu-top' : Menu,
    'toast' : Toast,
  },

  created: function(){
    this.checkstatus()
  },

  watch:{
    '$route.name' : function(){
      if( this.$route.name == 'login' ){
        this.isLogin = true
      }else{
        this.isLogin = false
      }
    },
  },

  mounted() {
    this.$root.toast = this.$refs.toast
  },

  methods:{
    checkstatus: function(){
      let $self = this
      window.addEventListener('online', () => $self.connection = true );
      window.addEventListener('offline', () => $self.connection = false );
    },

  }
  

}
</script>


<style lang="scss" scoped>


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
