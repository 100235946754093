import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null
  },

  mutations: {
    setUser: function(state, value){
      state.user = value;
    },
    getUser: function(state) {
      return state.user
    },
  },
  actions: {

    loadUser: async function(){
      let resp = await api.get( 'user/me' );
      this.commit('setUser', resp.data)
    },

    loginUser: function (context, payload) {
      return api
        .login({
          login: payload.login,
          password:   payload.password
        })
        .then(response => {
          localStorage.setItem( 'purimax-auth-token', `${response.data.token}` )
          this.commit('setUser', response.data.user )
        })
    },
    logoff: function () {
        localStorage.removeItem( 'purimax-auth-token' )
    },

  },
})