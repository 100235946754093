import { api } from '@/api';

export async function fetchEncomendas() {
  try {
      const response = await api.get(`api/encomendas`);
      return response.data;
  } catch (error) {
      console.error('Erro ao buscar encomendas:', error);
      throw error;
  }
}

// Função para cadastrar uma encomenda
export async function cadastrarEncomenda(encomenda) {
  try {
      const response = await api.post(`api/encomendas`, encomenda);
      return response.data; 
  } catch (error) {
      console.error('Erro ao cadastrar encomenda:', error);
      throw error; 
  }
}

// Função para atualizar o status de notificação
export async function atualizarStatusNotificacao(id) {
  try {
      const response = await api.put(`encomendas/${id}/notificar`, {});
      return response.data; 
  } catch (error) {
      console.error('Erro ao atualizar status de notificação:', error);
      throw error;
  }
}