<template lang="pug">
div
    v-app-bar( color="primary" app dark fixed :elevation="0"  )
        v-app-bar-nav-icon(@click.stop="MenuLeft = !MenuLeft")
        v-toolbar-title Purimax

    v-navigation-drawer(:mini-variant.sync="MenuLeft"  app fixed permanent)
        v-list-item(class="px-2 pb-1 pt-1 primary" dark )
          v-list-item-avatar
            v-icon mdi-account-circle

          v-list-item-content
            v-list-item-title(v-if="user") {{user.name}} {{user.lastname}}
        

        v-divider

        v-list(dense)

          v-list-item(to="/" link )
              v-list-item-icon
                  v-icon mdi-view-dashboard-outline

              v-list-item-content
                  v-list-item-title Home
          

          div(v-for="item in items" :key="item.title" v-acl.can.hide='`${item.role}`')
            v-list-item(v-if="!item.childrens" :to="item.route" link )
              v-list-item-icon
                  v-icon {{ item.icon }}

              v-list-item-content
                  v-list-item-title {{ item.title }}
            
            v-list-group(v-if="item.childrens" no-action)
              template( slot="activator" )

                v-list-item-icon
                  v-icon {{ item.icon }}

                v-list-item-content
                  v-list-item-title {{ item.title }}

              v-list-item(:to="item.route" exact link)

                v-list-item-content
                    v-list-item-title {{ item.title }}
                
                v-list-item-icon
                    v-icon(small) {{ item.icon }}
                

              v-list-item(v-for="sitem in item.childrens" :key="sitem.title" :to="sitem.route" v-acl.can.hide='`${sitem.role}`' link)
                
                v-list-item-content
                  v-list-item-title {{ sitem.title }}

                v-list-item-icon
                  v-icon(small) {{ sitem.icon }}

          v-list-item(@click="logoff" link)
            v-list-item-icon
                v-icon mdi-logout-variant
            

            v-list-item-content
                v-list-item-title Sair



            
    
    
</template>

<script>
export default {
  name: "Menu",
  data: () => ({
    MenuLeft: null,
    items: [
        {
          title: "Clientes",
          icon: "mdi-account-group-outline",
          route: '/clients',
          role: 'client.list'
        },
        {
          title: "Pedidos",
          icon: "mdi-clipboard-text-multiple-outline",
          route: '/orders',
          role: 'order.list',
          childrens:[
            {
              title: "Novo pedido",
              icon: "mdi-plus",
              route: '/orders/add',
              role: 'order.add'
            },
          ]
        },

        {
          title: "Protocolos",
          icon: "mdi-clipboard-text-multiple-outline",
          route: '/protocols',
          role: 'protocol.list',
          childrens:[
            {
              title: "Novo protocolo",
              icon: "mdi-plus",
              route: '/protocols/add',
              role: 'protocol.add'
            },
          ]
        },

        {
          title: "Entregadores",
          icon: "mdi-moped-outline",
          route: '/couriers',
          role: 'courier.list',
          childrens:[
            {
              title: "Novo entregador",
              icon: "mdi-plus",
              route: '/couriers/add',
              role: 'courier.add'
            },
          ]
        },

        {
          title: "Produtos",
          icon: "mdi-book-open-outline",
          route: '/products',
          role: 'product.list'
        },
        {
          title: "Usuários",
          icon: "mdi-account-group-outline",
          route: '/users',
          role: 'user.list',
          childrens:[
            {
              title: "Novo Usuário",
              icon: "mdi-account-outline",
              route: '/users/add',
              role: 'user.add'
            },
            {
              title: "Cargos",
              icon: "mdi-account-outline",
              route: '/roles',
              role: 'role.list'
            },
          ]
        },
        {
          title: "WhatsApp",
          icon: "mdi-whatsapp",
          route: '/whatsapp',
          role: 'whatsapp.view'
        },
        {
          title: "Encomendas",
          icon: "mdi-package-variant",
          route: '/encomendas/listagem',
          role: 'encomendas.listagem',
          childrens: [
            {
              title: "Cadastrar Encomendas",
              icon: "mdi-plus-box",
              route: '/encomendas/cadastro',
              role: 'encomendas.cadastro'
            }
          ]
        },
    ],
  }),

  methods:{
    logoff: async function(){
      try {
          await this.$store.dispatch( 'logoff')
          this.$router.push({
              name: 'login'
          })
      } catch (error) {
        alert( error )
      }
    },
  },

  computed: {
    user: function(){
      return this.$store.state.user;
    },
  }
  
  
};
</script>
