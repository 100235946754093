<template>
    <div class="pa-10">
        <h1>Encomendas Programadas</h1>

        <!-- Resumo em Tabela -->
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td><strong>Total de Encomendas</strong></td>
                                    <td>{{ encomendas.length }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Total de Clientes Não Notificados</strong></td>
                                    <td>{{ totalNaoNotificados }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>

        <!-- Campo de Pesquisa -->
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="searchQuery"
                        label="Pesquisar pelo Nome, Telefone, Produto ou Notificado (Sim/Não)"
                        outlined
                        clearable
                        hide-details
                    >
                        <template v-slot:append>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <!-- Tabela -->
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Cliente</th>
                                    <th>WhatsApp</th>
                                    <th>Produto</th>
                                    <th>Data Prevista</th>
                                    <th>Notificado</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="encomenda in paginatedEncomendas" :key="encomenda.id">
                                    <td>{{ encomenda.id }}</td>
                                    <td>{{ encomenda.cliente_nome }}</td>
                                    <td>{{ encomenda.whatsapp_numero }}</td>
                                    <td>{{ encomenda.produto_nome }}</td>
                                    <td>{{ calcularDiasRestantes(encomenda.data_prevista) }}</td>
                                    <td>
                                        <span
                                            :class="{
                                                'status-nao': encomenda.notificado === 0,
                                                'status-sim': encomenda.notificado === 1
                                            }"
                                        >
                                            {{ encomenda.notificado ? 'Sim' : 'Não' }}
                                        </span>
                                    </td>
                                    <td>
                                        <v-btn 
                                            color="primary" 
                                            @click="confirmarEnvio(encomenda)"
                                            outlined
                                            large
                                        >
                                            <v-icon left>mdi-whatsapp</v-icon>
                                            Notificar Cliente
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-if="paginatedEncomendas.length === 0">
                                    <td colspan="7" class="text-center">Nenhuma encomenda encontrada</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <!-- Paginação -->
                    <v-pagination
                        v-if="filteredEncomendas.length > itemsPerPage"
                        v-model="currentPage"
                        :length="totalPages"
                        :total-visible="5"
                        class="mt-4"
                    ></v-pagination>
                </v-col>
            </v-row>
        </v-container>

        <!-- Dialogo de Confirmação -->
        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Confirmação</v-card-title>
                <v-card-text>
                    Esse cliente já foi notificado, deseja notificar novamente?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="red" text @click="cancelarEnvio">Não</v-btn>
                    <v-btn color="green" text @click="confirmarEnvioDefinitivo">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { atualizarStatusNotificacao, fetchEncomendas } from '@/services/Encomendas'; // Importando a função do api.js

export default {
    name: 'ListarEncomendas',
    data() {
        return {
            encomendas: [], // Lista de encomendas
            searchQuery: '', // Query de pesquisa
            currentPage: 1, // Página atual
            itemsPerPage: 50, // Itens por página
            dialog: false, // Controle do diálogo
            selectedEncomenda: null // Encomenda selecionada para confirmação
        };
    },
    async created() {
        try {
            // Carregar as encomendas ao criar o componente
            this.encomendas = await fetchEncomendas();
        } catch (error) {
            console.error('Erro ao carregar encomendas:', error);
        }
    },
    computed: {
        // Total de clientes não notificados
        totalNaoNotificados() {
            return this.encomendas.filter(encomenda => encomenda.notificado === 0).length;
        },
        // Lista filtrada com base no campo de pesquisa
        filteredEncomendas() {
            const query = this.searchQuery.toLowerCase();
            return this.encomendas.filter((encomenda) => {
                const notificado = encomenda.notificado ? 'sim' : 'não';
                return (
                    encomenda.cliente_nome.toLowerCase().includes(query) ||
                    encomenda.whatsapp_numero.includes(query) ||
                    encomenda.produto_nome.toLowerCase().includes(query) ||
                    notificado.includes(query)
                );
            });
        },
        // Total de páginas
        totalPages() {
            return Math.ceil(this.filteredEncomendas.length / this.itemsPerPage);
        },
        // Itens paginados
        paginatedEncomendas() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredEncomendas.slice(start, end);
        }
    },
    methods: {
        calcularDiasRestantes(dataPrevista) {
            const dataAtual = new Date();
            const dataEncomenda = new Date(dataPrevista);
            const diferencaEmMilissegundos = dataEncomenda - dataAtual;

            if (diferencaEmMilissegundos < 0) {
                return 'Data já passou';
            }

            const diasRestantes = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
            return `Faltam ${diasRestantes} dias`;
        },

        confirmarEnvio(encomenda) {
            if (encomenda.notificado) {
                this.selectedEncomenda = encomenda;
                this.dialog = true;
            } else {
                this.notificarCliente(encomenda);
            }
        },

        cancelarEnvio() {
            this.dialog = false;
            this.selectedEncomenda = null;
        },

        confirmarEnvioDefinitivo() {
            this.dialog = false;
            if (this.selectedEncomenda) {
                this.notificarCliente(this.selectedEncomenda);
                this.selectedEncomenda = null;
            }
        },

        async notificarCliente(encomenda) {
            try {
                
                await atualizarStatusNotificacao(encomenda.id); // Usando a função do api.js
                encomenda.notificado = 1; // Atualizar estado local

                this.$root.toast.show({
                    message: `Notificação enviada para ${encomenda.cliente_nome}`,
                    color: 'success',
                    icon: 'mdi-check-circle'
                });
                
            } catch (error) {
                console.error('Erro ao notificar cliente:', error);
                this.$root.toast.show({
                    message: `Erro ao notificar ${encomenda.cliente_nome}`,
                    color: 'error',
                    icon: 'mdi-alert-circle'
                });
            }
        }
    }
};
</script>

<style scoped>
.pa-10 {
    padding: 10px;
}
.text-center {
    text-align: center;
}
.status-nao {
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}
.status-sim {
    background-color: green;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}
</style>
