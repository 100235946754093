import Vue from 'vue'
import App from './App.vue'



// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'

import Vuetify from 'vuetify';
import vuetify from '@/plugins/vuetify';
import VueTheMask from 'vue-the-mask'
import VuetifyMask from 'vuetify-mask';


import router from './router'

import '../src/assets/scss/theme.scss'

import store from './store'
import {api} from './api'
import {config} from './config'
import ACL from './plugins/acl';

import axios from 'axios'


Vue.use( Vuetify )
// Vue.config.productionTip = false
Vue.prototype.api = api
Vue.prototype.config = config
Vue.prototype.$http = axios

Vue.use(VueTheMask)
Vue.use(VuetifyMask);


// Global filters
import './filters'

// Global directives
import './directives'



let app = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created: function () {
    Vue.use(ACL, this.$store)
  },
}).$mount('#app')

global.app = app

